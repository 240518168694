import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Stack {
  constructor(element) {
    this.element = element;
  }

  mount() {
    this.element.classList.add("is-viewport");
    if(this.element.classList.contains("illu--wool")){
      document.querySelector(".illu--wool").unpauseAnimations();
    }
  }

  unmount() {
    if(this.element.classList.contains("is-viewport")){
      this.element.classList.remove("is-viewport");
    }
  }
}
