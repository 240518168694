import 'mdn-polyfills/NodeList.prototype.forEach';

document.addEventListener('DOMContentLoaded', () => {

  const horizonts = document.querySelectorAll('.c-horizont');
  if (!horizonts.length) {
    return;
  }

  let horizontBox = document.querySelector(".c-horizont");

  if (window.matchMedia("(prefers-reduced-motion: no-preference)").matches) {

    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
        if(entry.isIntersecting){
          const progress = Math.round((entry.intersectionRatio)*100)/100;
          horizontBox.style.setProperty('--scroll', progress);
        }
        });
      }, {
        rootMargin: "0px 0px 0px 0px",
        threshold: Array.apply(null, {length: 100}).map((n, i) => i/100)
      });
      document.querySelectorAll('.c-horizont').forEach(horizont => { observer.observe(horizont) });
    }

    else document.querySelector('#warning').style.display = 'block';
  }else{
    horizontBox.style.setProperty('--scroll', 1);
  }

});



