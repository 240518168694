import 'mdn-polyfills/NodeList.prototype.forEach';

document.addEventListener('DOMContentLoaded', () => {



    if (document.querySelector('.c-scenario')) {

    let isIntersecting = false;
    let progress = 0;


    const scene = document.querySelector('.c-scenario');
    let animationLonger = 0.5;
    let animationPlaceholder = document.querySelector(".c-scenario__placeholder");
    let animationIllu = document.querySelector(".c-scenario__illu");

    if (window.matchMedia("(prefers-reduced-motion: no-preference)").matches) {
      scenario();

      function scenario(){
        let viewportHeight;
        let vh;
        let illuHeight;
        let triggerStart;

        const update = function() {
          viewportHeight = window.innerHeight;
          vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);

          illuHeight = viewportHeight * animationLonger;
          triggerStart = scene.getBoundingClientRect().top + window.scrollY;

          animationPlaceholder.style.height = illuHeight + "px";
        }

        const loop = function() {
          let temp = progress;
          let scrollY = window.scrollY;
          let triggerEnd = triggerStart + scene.offsetHeight - window.innerHeight;

          if(scrollY >= triggerStart && scrollY <= triggerEnd) {
            progress = (scrollY - triggerStart) / (triggerEnd - triggerStart);
          } else if (scrollY < triggerStart) {
            progress = 0;
            animationIllu.className="c-scenario__illu";
          } else {
            progress = 1;
            animationIllu.className="c-scenario__illu is-finished";
          }

          if(temp !== progress) {
            scene.style.setProperty('--scroll', progress);

          }

          if(isIntersecting) {
            requestAnimationFrame(loop);
          } else {
            cancelAnimationFrame(loop);
          }
        }

        const callback = (entries, observer) => {
          entries.forEach((entry) => {
            isIntersecting = entry.isIntersecting;
            if(isIntersecting) {
              requestAnimationFrame(loop);
            } else {
              cancelAnimationFrame(loop);
            }
          });
        };

        window.addEventListener('resize', function(event) {
          update();
        }, true);

        const options = {
          root: null,
          rootMargin: '0px',
          threshold: [0, 1]
        };

        update();

        const observer = new IntersectionObserver(callback, options);
        observer.observe(scene);
      }
    }else{
      scene.style.setProperty('--scroll', 1);
    }
  }

});
